<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Frequently Asked Questions</h2>
        </div>
    </div>
</div>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>AyudaDesk</span></h2>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    How does the pricing work? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    All plans are based on a per agent price of $15/month. The Professional plan is a monthly subscription and email integration cost an additional $25/month. The Enterprise plan requires an annual subscription and email integration is included.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Does the price go up as my team gets larger? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    No, you pay per agent and there's no limit as to how many agents you can add for all plans.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    How can I cancel my subscription? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    Our Professional plan is month to month so you can cancel at any time, but no refund will be issue since you will continue to have access until your next monthly renewal date. For Enterprise plan, a 90-day notice is required but you will continue to have access until the end of your next annual renewal date.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    What payment methods to you accept? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    We accept all major credit cards including PayPal. Checks are only accepted in limited cases and only for the enterprise plan.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Who do I contact for help? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    If you are currently an AyudaDesk customer, please contact us at <a href="mailto:support@ayudadesk.com">support@ayudadesk.com</a> for all your needs. If you are on a free or expired trial, please contact us at <a href="mailto:info@ayudadesk.com">info@ayudadesk.com</a> for help in upgrading to a subscription plan.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="subscribe-area bg-F2F2F2">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>
            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="email" name="EMAIL" required>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>-->
            <!--<div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/shape15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/shape16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/shape17.png" alt="image"></div>-->
        <!--</div>
    </div>
    <br/>
</section>
-->
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>