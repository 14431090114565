<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ title }}</h2>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <h3>Schedule a Demo</h3>
                </div>
                <div class="container">
                    <div class="row">
                        <iframe width="100%" height="1024"
                            src="https://calendly.com/kiong/demo-ayudadesk?month=2021-07"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="contact-form">
                    <h3>Request a Quote</h3>
                    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" formControlName="name"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Name">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" formControlName="email"
                                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                        placeholder="Email">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Required</div>
                                        <div *ngIf="f.email.errors.email">Must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number"
                                        formControlName="phone_number"
                                        [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                                        placeholder="(000) 000-0000" mask="(000) 000-0000" class="form-control">
                                    <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone_number.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="company_name" id="company_name"
                                        formControlName="company_name" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }"
                                        placeholder="Company Name">
                                    <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                        <div *ngIf="f.company_name.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="company_location" id="company_location"
                                        formControlName="company_location" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.company_location.errors }"
                                        placeholder="Company Location">
                                    <div *ngIf="submitted && f.company_location.errors" class="invalid-feedback">
                                        <div *ngIf="f.company_location.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="company_website" class="form-control" id="company_website"
                                        formControlName="company_website" cols="30" rows="6"
                                        placeholder="Company Website">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="no_of_employees" id="no_of_employees"
                                        formControlName="no_of_employees" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.no_of_employees.errors }"
                                        placeholder="No of Employees">
                                    <div *ngIf="submitted && f.no_of_employees.errors" class="invalid-feedback">
                                        <div *ngIf="f.no_of_employees.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" formControlName="message"
                                        cols="30" rows="6" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class='bx bxs-paper-plane'
                                        style="color: #ffffff;"></i>Send Message<span></span></button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>
<ngx-spinner bdColor="rgba(72,191,145,0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>