import { Component, OnInit } from '@angular/core';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor(
    public dialog: MatDialog,

  ) { }

  ngOnInit() {
  }
}
