<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
        </div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">

        <div class="service-details pb-70">
            <div class="container">
                <div class="">
                    <h2>A plan for all healthcare organizations</h2>
                    <p>From small to enterprise level support teams, AyudaDesk has a plan to meet your organizational needs.
                     Scale as you grow with unlimited everything included on all plans.</p>
                    <!-- <p>Try AyudaDesk free for 14 days, no credit card needed.</p> -->
                </div>
            </div>
        </div>

        <div class="tab pricing-list-tab">
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <!-- <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align pricing-tab">
                                <div class="pricing-header">
                                    <h3>Free Trial</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ 14 days</sub>
                                    
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Unlimited Agents & Vendors</li>

                                    <li><i class="bx bxs-badge-check"></i> Unlimited Client Portals</li>

                                    <li><i class="bx bxs-badge-check"></i> Unlimited Forms & Reviews</li>

                                    <li><i class="bx bxs-badge-check"></i> Unlimited Storage</li>
                                    
                                </ul>
                                <div>&nbsp;</div>
                                <div class="btn-box">
                                    <a class="default-btn" routerLink="/free-trial" style="color:white; cursor: pointer;"><i class="bx bxs-hot"></i> Try Now<span></span></a>
                                </div>
                                
                            </div>
                        </div> -->

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-pricing-table left-align pricing-tab">
                                <div class="pricing-header">
                                    <h3>Professional </h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>15 <sub>/ month / agent</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Everything in Free Trial</li>

                                    <li><i class="bx bxs-badge-check"></i> Business Associate Agreement (BAA)</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration</li>
                                    
                                    <li><i class="bx bxs-badge-check"></i> Remote Training & Support</li>

                                </ul>
                                <div class="btn-box">
                                    <a class="default-btn" (click)="redirectDemo('Professional')" style="color:white; cursor: pointer;"><i class="bx bxs-hot"></i>Get Quote<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align pricing-tab">
                                <div class="pricing-header">
                                    <h3>Enterprise </h3>
                                </div>
                                <div class="price">
                                    <sup class="Enterprice-top"><i class="bx bxs-phone"></i>&nbsp;Contact us</sup> <sub></sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Everything in Professional</li>

                                    <li><i class="bx bxs-badge-check"></i> Custom Dashboard Reports</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration Included</li>
                                    
                                    <!-- <li><i class="bx bxs-badge-check"></i> Onsite Training & Support</li> -->

                                </ul>
                                <div>&nbsp;</div>
                                <!-- <div>&nbsp;</div> -->
                                <div class="btn-box" style="margin-top: 40px;">
                                    <a class="default-btn" (click)="redirectDemo('Enterprise')" style="color:white; cursor: pointer;"><i class="bx bxs-hot"></i>Get Quote<span></span></a>
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!--
<section class="our-loving-clients ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>
-->
<!-- <section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content">
            <h2>We provide HIPAA complaint helpdesk support platform</h2>
            <p>Simplify the support team’s work to satisfy all healthcare professionals with one workspace on a HIPAA complaint platform.</p>
            <a routerLink="/contact" class="default-btn contact-bcolor"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section> -->
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>