import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TryNowService } from './try-now.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-try-now',
  templateUrl: './try-now.component.html',
  styleUrls: ['./try-now.component.scss']
})
export class TryNowComponent implements OnInit {
  signupform: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private TryNowService: TryNowService,
  ) { }

  ngOnInit() {
    this.signupform = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      companyname: ['', Validators.required],
      phone: ['', Validators.required]
    })
  }

  get f() { return this.signupform.controls; }

  onSubmit() {
    this.submitted = true;
    const controls = this.signupform.controls;
    
    if (this.signupform.invalid) {
      return;
  }

   const authData = {
      CompanyName :controls.companyname.value,
      FirstName :controls.firstname.value,
      LastName :controls.lastname.value,
      Email : controls.email.value,
      PhoneNo: controls.phone.value
    }

    this.TryNowService.signup(authData).subscribe((res) =>{
    	  if(!res.isFault){
          // this.userData = res.responseData;
          // this.layoutUtilService.showSuccess(res.responseData.message,'');
          
          // this.cdr.markForCheck();
          
           // this.router.navigateByUrl('/auth/login');
           console.log(res.responseData.message);
           this.showMessage(res.responseData.message);
           //this.signupform.reset()
        }

      else{
        // stop the loading and show the error message
        
        // this.layoutUtilService.showError(res.responseData.message,'');
        // this.cdr.markForCheck()
        console.log(res.responseData.message);
        this.showerror(res.responseData.message);
      }
    },(err) =>{
       // stop the loading and show the error message
     
      // this.layoutUtilService.showError(err,'');
    });
  }

  showMessage(message){
    Swal.fire({
        text: message,
        icon: 'success',
        confirmButtonColor: '#549be3'
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      })
      
  }
  showerror(message){
    Swal.fire({
        text: message,
        icon: 'error',
        confirmButtonColor: '#fa4e5e'
      });
      
  }


  isControlHasError(name , control){
    
  }
}
