import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from './request-a-demo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-request-a-demo',
  templateUrl: './request-a-demo.component.html',
  styleUrls: ['./request-a-demo.component.scss']
})
export class RequestADemoComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;

  title: any;
  descriptionTitle: any;

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {

   }

  ngOnInit(): void {
    this.route.params.forEach((urlParams) => {
      this.title = urlParams['title'];
    });
    if(this.title == 'Standard')
    {
      this.descriptionTitle = 'Standard Sub Title';
    }
    else if(this.title == 'Professional')
    {
      this.descriptionTitle = 'Professional Sub Title';
    }
    else if(this.title == 'Enterprise')
    {
      this.descriptionTitle = 'Enterprise Sub Title';
    }
    else if(this.title == 'Request Demo')
    {
      this.descriptionTitle = 'Contact us for more details.';
    }
    console.log(this.descriptionTitle);
    this.cdr.markForCheck();

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      company_name: ['', Validators.required],
      company_location: ['', Validators.required],
      company_website: [''],
      no_of_employees: ['', Validators.required],
      message: [''],
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
        return;
    }

    // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactForm.value, null, 4));
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.title, null, 4));
   
    const controls = this.contactForm.controls;

    const authData = {
      title: this.title,
			name: controls.name.value,
      email: controls.email.value,
      phone: controls.phone_number.value,
      companyname: controls.company_name.value,
      companylocation: controls.company_location.value,
      companywebsite: controls.company_website.value,
      noofemployees: controls.no_of_employees.value,
      message: controls.message.value,
    };
    this.ngxSpinnerService.show();
    this.contactService.contactDetails(authData)
      .subscribe((res) => {
        if (!res.isFault) {
          this.ngxSpinnerService.hide(); 
         this.showMessage(res.responseData);
        } else {
          this.ngxSpinnerService.hide();
         this.showerror(res.responseData);
        }
      });
  }
  showMessage(message){
    Swal.fire({
        text: message,
        icon: 'success',
        confirmButtonColor: '#549be3'
      }).then((result) => {
        if (result.isConfirmed) {
         location.reload();
        }
      })
      
  }
  showerror(message){
    Swal.fire({
        text: message,
        icon: 'error',
        confirmButtonColor: '#fa4e5e'
      });
      
  }
}
