import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(
    private router : Router, 
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta) { 
    }

    ngOnInit() {
      
  }

  redirectDemo(res)
  {
    this.router.navigate(['/request-a-demo', {
      title: res
    }]);
  }
}
