import { Component, OnInit } from '@angular/core';

//helpdesk-enhance-healthcare-providers-efficiency
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
//best-collaborative-helpdesk-software-for-healthcare
@Component({
  selector: 'app-blog-3',
  templateUrl: './blog-3.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class Blog3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
//importance-of-customer-service-in-healthcare-industry
@Component({
  selector: 'app-blog-3',
  templateUrl: './blog-2.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class Blog2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
//hipaa-compliant-help-desk-software
@Component({
  selector: 'app-blog-3',
  templateUrl: './blog-4.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class Blog4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
//healthcare-helpdesk-software
@Component({
  selector: 'app-blog-3',
  templateUrl: './blog-5.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class Blog5Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
//why-helpdesk-software
@Component({
  selector: 'app-blog-3',
  templateUrl: './blog-6.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class Blog6Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}