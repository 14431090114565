<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="image"></a>
                    </div>
                    <ul class="support-list">
                        <li><a routerLink="/faq">FAQs</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/term-and-conditions">Terms & Conditions</a></li>
                    </ul>
                    <!--<p>Online ticketing system to track multiple agency's healthcare providers service requests on the go!</p>-->
                </div>
            </div>
            <!--<div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-2">Features</a></li>
                        //<li><a routerLink="/pricing">Our Pricing</a></li>
                        //<li><a routerLink="/blog-1">Latest News</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    
                </div>
            </div>-->
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: Miami, Florida, USA</li>
                        <li>Email: <a href="mailto:info@ayudadesk.com">info@ayudadesk.com</a></li>
                        <!--<li>Phone: <a href="tel:+1 561-444-9174">+1 561-444-9174</a></li>-->
                        <a routerLink="/contact" class="default-btn contact-bcolor"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/ayudadesk" target="_blank" style="color: white;"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ayuda_desk" target="_blank" style="color: white;"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/ayudadesk/" target="_blank" style="color: white;"><i class="bx bxl-linkedin"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright © {{currentDate | date: 'yyyy'}} AyudaDesk, Inc. All Rights Reserved </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>