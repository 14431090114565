<div class="main-banner chatbot-main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h2>Helpdesk for Healthcare!</h2>
                                <p style="font-size: 21px;">AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.</p>
                                <p style="font-size: 21px;">Communicate, collaborate and manage your teams, vendors and clients, from anywhere and on any device.</p>
                            </div>
                            
                            <!-- <div class="subscribe-content">
                                <form class="newsletter-form">
                                    <div class="row">
                                        <div class="col-lg-8 col-md-8">
                                            <input type="email" class="form-control" placeholder="email" name="EMAIL" required>
                                            <p>Reserve time for a discussion and demo focused on your needs.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <button type="submit" class="contact-bcolor">Let’s connect in person</button>
                                        </div>
                                    </div>
                                </form>
                            
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-image bg-2">
                    <app-chatting></app-chatting>
                    <img src="assets/img/about-img1.png" alt="image">
                    <!--banner-img2.jpeg-->
                </div>
            </div>
        </div>
    </div>
    <!--<div class="shape20"><img src="assets/img/shape/shape19.png" alt="image"></div>
    <div class="shape21"><img src="assets/img/shape/shape20.png" alt="image"></div>-->

    <div class="shape19"><img src="assets/img/shape/shape18.png" alt="image"></div>
    <!--<div class="shape22"><img src="assets/img/shape/shape21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/shape22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/shape23.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/shape25.png" alt="image"></div>-->
</div>
<!--
<section class="partner-area pt-100 pb-70 bg-f8fbfa mt-35">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                ///<div class="partner-title">
                ///    <h3>Featured by:</h3>
                ///</div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="partner-slides owl-carousel owl-theme">
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img1.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img2.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img4.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<!--
<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/img2.png" alt="image">
                </div>
            </div>
            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Create our own forms to get detailed information to get tickets.</h2>
                    <p>Create form(s) with all questions for customers to submit information while creating a ticket. Respond quickly with all details at one shot.</p>
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
-->


    <!-- <section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section> -->

<section style="background: #f2f2f2;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 text-center mt-5">
                <a href="https://www.himss.org/" target="_blank"><img src="assets/img/himss22.PNG"></a>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12" >
                <div class="subscribe-content mt-2" style="background: #f2f2f2;text-align: left;">
                    <h3><strong>Let’s connect at HIMSS22 in Orlando!</strong></h3>
                    <h4>Join us at booth #8140-66</h4>
                    <p>Reserve time for a discussion and demo focused on your needs.</p>   
                    <a routerLink="/ayudadesk-at-himss22" class="default-btn">Let’s connect in person<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".1s">
                    <div class="icon">
                        <i class='bx bx-wrench'></i>
                    </div>
                    <h3>Easy Setup</h3>
                    <p>No programming or IT knowledge needed. Multiple admin levels for each group to continue to improve their support process.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>Automation</h3>
                    <p>Automate ticket assignment directly to a team or agent for faster routing. Automate responses based on forms or tags selected.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".3s">
                    <div class="icon">
                        <i class='bx bx-link'></i>
                    </div>
                    <h3>Email Integration</h3>
                    <p>Open tickets using email workflows or easily integrate your existing support email workflows with automatic user matching.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Vendor Access</h3>
                    <p>Consolidate communication between your clients, agents and vendors for faster response and improved collaboration.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".5s">
                    <div class="icon">
                        <i class='bx bx-log-in'></i>
                    </div>
                    <h3>Client Portal</h3>
                    <p>Unlimited web portals for each client to open, track and update tickets. Web portal, email and mobile app push notifications.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-star'></i>
                    </div>
                    <h3>Client Ratings</h3>
                    <p>Improve your customer service by capturing individual ticket written reviews and 5-star ratings for your agents and vendors.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image wow fadeInLeft" data-wow-delay=".3s">
                    <img src="assets/img/app_images.png" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Download App</span>
                    <h2>Provide support on the go with our mobile app.</h2>
                    <div class="btn-box">
                        <a href="https://apps.apple.com/us/app/ayudadesk/id1547239472" class="apple-store-btn"><img
                                src="assets/img/applestore.png" alt="image">Download on the<span>Apple Store</span></a>
                        <a href="https://play.google.com/store/apps/details?id=com.AyudaDesk.AD"
                            class="play-store-btn"><img src="assets/img/playstore.png" alt="image">Get it on<span>Google
                                Play</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="pt-100 pb-70">
    <div class="container">
        <div class="section-title" style="margin-bottom: 15px;">
            <h2>Events</h2>
        </div>
        <div class="row">
            <div class="col-6">
                <h2>Let’s meet in Orlando</h2>
                <p>Reserve time for a discussion and demo focused on your needs.</p>
                <a routerLink="/hyland" class="default-btn">Let’s connect in person</a>
                <h2>Join us at booth #2759</h2>
                <p>Be sure to drop by the Hyland booth #2759 - we promise you a magical experience. Look for the gathering crowd, or find us on the exhibitor map.</p>
            </div>
            <div class="col-6">

            </div>
        </div>
    </div>
</section> -->

<section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title" style="margin-bottom: 15px;">
            <h2>What Our Clients say about <span>AyudaDesk:</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item" style="margin-top: 15px;">
                <!-- <img src="assets/img/woman1.png" alt="image"> -->
                <div class="feedback-desc">
                    <p>AyudaDesk is clean and simple to use, responsive and capable of matching our growth. We ran comparisons with Salesforce for a month and our team voted unanimously for AyudaDesk. It was a smooth transition for our department and clients. We have improved our productivity, customer service and team collaboration. Exceeded all expectations!</p>
                    <div class="client-info mt-2">
                        <h3>Alexis Vasquez, EHR Director</h3>
                        <span>Femwell Group Health</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item" style="margin-top: 15px;">
                <!-- <img src="assets/img/woman2.png" alt="image"> -->
                <div class="feedback-desc">
                    <p>AyudaDesk has become one of the greatest tools that I’ve used. Whenever I submit a request, I know exactly who it gets assigned to and get updates along the way to getting my problem solved. AyudaDesk is quick, user friendly and solution focused. I highly recommend it.</p>
                    
                    <div class="client-info mt-2">
                        <h3>Ivan Carreras, MD</h3>
                        <span>Carreras Medical Center</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item" style="margin-top: 15px;">
                <!-- <img src="assets/img/man1.png" alt="image"> -->
                <div class="feedback-desc">
                    <p>Our office has been using AyudaDesk for over a year. Easy to use. No training needed. Completely self-explanatory. Able to quickly follow the timeline of when & which technician is working the support ticket. We also use the smartphone app to easily upload photos for documentation of the issue. Messaging feature allows for quick dialogue with the technician in real time. This support system is by far superior to anything else we have used.</p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info mt-2">
                        <h3>Julie Semel, Practice Administrator</h3>
                        <span>Gold Coast Women's Wellness Center</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item" style="margin-top: 15px;">
                <!-- <img src="assets/img/man1.png" alt="image"> -->
                <div class="feedback-desc">
                    <p>Powerful, intuitive, and easy to use comes to mind as my experience with AyudaDesk.  Pairs very well with the mobile app too.  It has everything I want and more to allow me to collaborate with internal teams and/or customers in the same ticket for fast and efficient resolution of issues.  I use it and would highly recommend it.</p>
                    <div class="client-info mt-2">
                        <h3>James Collura, Tech Support</h3>
                        <span>Visual-Med</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--section class="pricing-area pt-100 pb-70 bg-F2F2F2">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>49 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>99 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" data-toggle="tooltip" data-placement="right" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>AyudaDesk</span></h2>
                    <div class="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What access do I have on the free plan? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    What access do I have on a free trial? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Does the price go up as my team gets larger? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    How can I cancel/pause my subscription? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="card mb-0">
                            <div class="card-header" role="tab" id="headingFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Can I pay via an invoice? <i class="bx bx-plus"></i>
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                                <div class="card-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>
-->

<!--
<section class="services-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/feature1.png" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="features-inner-content">
                        <div class="features-item wow fadeInRight" data-wow-delay=".1s">
                            <i class='bx bx-phone-call bg-13c4a1 blt-radius-0'></i>
                            <h3>Free Calling Service</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                        <div class="features-item wow fadeInRight" data-wow-delay=".2s">
                            <i class='bx bx-gift bg-6610f2 blt-radius-0'></i>
                            <h3>Daily Free Gift</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                        <div class="features-item wow fadeInRight" data-wow-delay=".3s">
                            <i class='bx bx-code-alt bg-ff612f blt-radius-0'></i>
                            <h3>QR Code Scaner</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    ///<div class="shape9">
    ///    <img src="assets/img/shape/shape9.png" alt="image">
    ///</div>
</section>
-->
<section style="background: #f2f2f2;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 text-center mt-5">
                <a href="https://tech.einnews.com/pr_news/564095618/help-desk-system-enables-health-care-organizations-to-meet-demand-for-faster-service" target="_blank"><img src="assets/img/ein-presswire.png"></a>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12" >
                <div class="subscribe-content mt-2" style="background: #f2f2f2;text-align: left;">
                    <h3><strong>Help Desk System Enables Health Care Organizations to Meet Demand for Faster Service</strong></h3>
                    <p>Online help desk system streamlines ticketing processes for healthcare companies resulting in accurate and fast response to support requests.</p>   
                    <a href="https://tech.einnews.com/pr_news/564095618/help-desk-system-enables-health-care-organizations-to-meet-demand-for-faster-service" target="_blank" class="default-btn">Read More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>