import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from './contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  contactForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private ngxSpinnerService: NgxSpinnerService
    ) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required],
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
        return;
    }

    // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactForm.value, null, 4));

    const controls = this.contactForm.controls;

    const authData = {
			name: controls.name.value,
      email: controls.email.value,
      phone: controls.phone_number.value,
      subject: controls.msg_subject.value,
      message: controls.message.value,
    };
    this.ngxSpinnerService.show();
    this.contactService.contactDetails(authData)
      .subscribe((res) => {
        if (!res.isFault) {
          this.ngxSpinnerService.hide();
          this.showMessage();
         } else {
          this.ngxSpinnerService.hide();
          this.showerror();
          return;
        }
      });
}
showMessage(){
  Swal.fire({
      text: 'Thank you for reaching out to us. We will get in touch with you shortly',
      icon: 'success',
      confirmButtonColor: '#549be3'
    }).then((result) => {
      if (result.isConfirmed) {
        location.reload();
      }
    })
    
}

showerror(){
  Swal.fire({
      text: 'Please Try Again',
      icon: 'error',
      confirmButtonColor: '#fa4e5e'
    });
    
}
}
