<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms & Conditions</h2>
        </div>
    </div>
</div>

<section class="about-area ptb-100 ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content" > 
                    <!--<span class="sub-title">How T&C are</span>
                    <h2>Terms & Conditions</h2>
                    <p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY. BY ACCESSING OR USING THIS WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE BY THESE TERMS AND CONDITIONS AND ACCEPT THEM IN FULL, AS THEY MAY BE MODIFIED BY AYUDADESK'S FROM TIME - TO - TIME AND POSTED ON THIS WEBSITE. IF YOU DO NOT AGREE TO THIS AGREEMENT INCLUDING ANY REFERENCED POLICIES OR GUIDELINES, PLEASE IMMEDIATELY TERMINATE YOUR USE OF THE WEBSITE.</p>-->
                </div>
                <br/>
                <div class="contact-features-list" style="text-align: left;">
                    <!--<h3>Use of Services</h3>-->
                    <p>Use of the Services provided by AyudaDesk shall entail your agreement to be bound by the following Terms of Use. AyudaDesk is not responsible for any damages resulting from the use of this website by anyone. You will not use the website for illegal purposes. You will:</p>
                    <ul>
                        <li><i class='bx bx-badge-check'></i> Abide by all the applicable local, state, national and international laws and regulations regarding intellectual property;</li>
                        <li><i class='bx bx-badge-check'></i> Not interfere with or disrupt the use and enjoyment of the website by other users;</li>
                        <li><i class='bx bx-badge-check'></i> Not resell material on the website;</li>
                        <li><i class='bx bx-badge-check'></i> Not engage, directly or indirectly, in transmission of "spam," chain letters, junk mail or any other type of unsolicited communication; and</li>
                        <li><i class='bx bx-badge-check'></i> Not defame, harass, or abuse other users of the website.</li>
                    </ul>
                    <br/>

                    <h3>License</h3>
                    <p>All intellectual property contained within the AyudaDesk website such as patents, trademarks and copyrights are protected. Subject to all the terms and conditions of this agreement, AyudaDesk will grant a non-exclusive, non-transferable, limited right to access this site and the materials thereon and not to reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate the contents to anyone.</p>
                    <br/>

                    <h3>Copyrights & Trademarks</h3>
                    <p>All rights reserved. The entire content including logos, brand nameplates, emblems, images, service marks, collective marks, design rights, text, graphics, sound, animations and videos that are available on the AyudaDesk website are protected by copyright, trademark and other commercial protective rights. Changing, altering or removing the copyright notice or any material on the website is prohibited. Some trademarks used on this website that are not the intellectual property of AyudaDesk are the property of their respective owners.</p>
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>
