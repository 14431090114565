export class AppSettings {
    //*****staging*****
    // static readonly REST_ENDPOINT_trynow = "http://18.189.33.46/ayapi/signUp";
    // static readonly REST_ENDPOINT_requstdemo = "http://18.189.33.46/ayapi/requestEmail";
    // static readonly REST_ENDPOINT_contact = "http://18.189.33.46/ayapi/contactEmail";

    //*****production*****
    static readonly REST_ENDPOINT_trynow = "https://support.ayudadesk.com/ayapi/signUp";
    static readonly REST_ENDPOINT_requstdemo = "https://support.ayudadesk.com/ayapi/requestEmail";
    static readonly REST_ENDPOINT_contact = "https://support.ayudadesk.com/ayapi/contactEmail";
}