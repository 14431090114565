import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { HomeSevenComponent } from './components/pages/home-seven/home-seven.component';
import { WithoutAnimationHomeOneComponent } from './components/pages/without-animation-home-one/without-animation-home-one.component';
import { WithoutAnimationHomeTwoComponent } from './components/pages/without-animation-home-two/without-animation-home-two.component';
import { WithoutAnimationHomeThreeComponent } from './components/pages/without-animation-home-three/without-animation-home-three.component';
import { WithoutAnimationHomeFourComponent } from './components/pages/without-animation-home-four/without-animation-home-four.component';
import { WithoutAnimationHomeFiveComponent } from './components/pages/without-animation-home-five/without-animation-home-five.component';
import { WithoutAnimationHomeSixComponent } from './components/pages/without-animation-home-six/without-animation-home-six.component';
import { WithoutAnimationHomeSevenComponent } from './components/pages/without-animation-home-seven/without-animation-home-seven.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LogInComponent } from './components/pages/log-in/log-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { ServicesOneComponent } from './components/pages/services-one/services-one.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { FeaturesOneComponent } from './components/pages/features-one/features-one.component';
import { FeaturesTwoComponent } from './components/pages/features-two/features-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent, Blog3Component, Blog2Component, Blog4Component, Blog5Component, Blog6Component } from './components/pages/blog-details/blog-details.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermAndConditionsComponent } from './components/pages/term-and-conditions/term-and-conditions.component';
import { RequestADemoComponent } from './components/pages/request-a-demo/request-a-demo.component';
import { TryNowComponent } from './components/pages/try-now/try-now.component';
import { HylandBoothComponent } from './components/pages/hyland-booth/hyland-booth.component';


const routes: Routes = [
    {path: '', component: HomeOneComponent, data: {
        title: 'Helpdesk Software for Healthcare - AyudaDesk',
        description:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        keywords:'healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software, healthcare help desk ticketing tool, healthcare Service Desk Software, help desk software for Healthcare, ticketing system for Healthcare, Healthcare Helpdesk Solutions',
        Ogurl:'https://www.ayudadesk.com',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'Helpdesk Software for Healthcare - AyudaDesk',
        OgDescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        twittertitle:'Helpdesk Software for Healthcare - AyudaDesk',
        twitterdescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "Helpdesk Software for Healthcare - AyudaDesk",

          "alternateName": "healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software, healthcare help desk ticketing tool, healthcare Service Desk Software, help desk software for Healthcare, ticketing system for Healthcare, Healthcare Helpdesk Solutions",

          "url": "https://www.ayudadesk.com/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "Helpdesk Software for Healthcare - AyudaDesk",

          "url": "https://www.ayudadesk.com/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'about', component: AboutComponent,
    data: {
        title: 'About AyudaDesk - Helpdesk Software for Healthcare',
        description:'Ayuda means Help in Spanish, so it translates to Helpdesk. Our mission at AyudaDesk is to help make a difference in the healthcare industry by making support simple.',
        keywords:'healthcare ticketing software, healthcare help desk software, healthcare ticketing system, healthcare customer service software, healthcare help desk management software',
        Ogurl:'https://www.ayudadesk.com/about',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'About AyudaDesk - Helpdesk Software for Healthcare',
        OgDescription:'Ayuda means Help in Spanish, so it translates to Helpdesk. Our mission at AyudaDesk is to help make a difference in the healthcare industry by making support simple.',
        twittertitle:'About AyudaDesk - Helpdesk Software for Healthcare',
        twitterdescription:'Ayuda means Help in Spanish, so it translates to Helpdesk. Our mission at AyudaDesk is to help make a difference in the healthcare industry by making support simple.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/about',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/about",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/about/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "About AyudaDesk - Helpdesk Software for Healthcare",

          "alternateName": "healthcare ticketing software, healthcare help desk software, healthcare ticketing system, healthcare customer service software, healthcare help desk management software",

          "url": "https://www.ayudadesk.com/about"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "About AyudaDesk - Helpdesk Software for Healthcare",

          "url": "https://www.ayudadesk.com/about",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'pricing', component: PricingComponent,
    data: {
        title: 'AyudaDesk Pricing - A plan For All Healthcare Organizations',
        description:'From small to enterprise level support teams, AyudaDesk helpdesk has a plan to meet your organizational needs. Scale as you grow with unlimited everything included on all plans.',
        keywords:'healthcare helpdesk pricing plans, healthcare helpdesk software pricing, healthcare helpdesk tool pricing',
        Ogurl:'https://www.ayudadesk.com/pricing',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'AyudaDesk Pricing - A plan For All Healthcare Organizations',
        OgDescription:'From small to enterprise level support teams, AyudaDesk helpdesk has a plan to meet your organizational needs. Scale as you grow with unlimited everything included on all plans.',
        twittertitle:'AyudaDesk Pricing - A plan For All Healthcare Organizations',
        twitterdescription:'From small to enterprise level support teams, AyudaDesk helpdesk has a plan to meet your organizational needs. Scale as you grow with unlimited everything included on all plans.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/pricing',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/pricing",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/pricing/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "AyudaDesk Pricing - A plan For All Healthcare Organizations",

          "alternateName": "healthcare helpdesk pricing plans, healthcare helpdesk software pricing, healthcare helpdesk tool pricing",

          "url": "https://www.ayudadesk.com/pricing/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "AyudaDesk Pricing - A plan For All Healthcare Organizations",

          "url": "https://www.ayudadesk.com/pricing",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "From small to enterprise level support teams, AyudaDesk helpdesk has a plan to meet your organizational needs. Scale as you grow with unlimited everything included on all plans.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'services-1', component: ServicesOneComponent},
    {path: 'features', component: ServicesTwoComponent,
    data: {
        title: 'AyudaDesk Features - Helpdesk Support Software Features',
        description:'Here is an inclusive list of all the exhaustive features of AyudaDesk healthcare helpdesk software. Read ahead to find out what each feature can do for your business and improve support practices for your customers.',
        keywords:'healthcare helpdesk software features, help desk software features, help desk system features, ayudadesk help desk features',
        Ogurl:'https://www.ayudadesk.com/features',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'AyudaDesk Features - Helpdesk Support Software Features',
        OgDescription:'Here is an inclusive list of all the exhaustive features of AyudaDesk healthcare helpdesk software. Read ahead to find out what each feature can do for your business and improve support practices for your customers.',
        twittertitle:'AyudaDesk Features - Helpdesk Support Software Features',
        twitterdescription:'Here is an inclusive list of all the exhaustive features of AyudaDesk healthcare helpdesk software. Read ahead to find out what each feature can do for your business and improve support practices for your customers.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/features',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/features",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/features/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "AyudaDesk Features - Helpdesk Support Software Features",

          "alternateName": "healthcare helpdesk software features, help desk software features, help desk system features, ayudadesk help desk features",

          "url": "https://www.ayudadesk.com/features/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "AyudaDesk Features - Helpdesk Support Software Features",

          "url": "https://www.ayudadesk.com/features/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "Here is an inclusive list of all the exhaustive features of AyudaDesk healthcare helpdesk software. Read ahead to find out what each feature can do for your business and improve support practices for your customers.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'features-1', component: FeaturesOneComponent},
    {path: 'features-2', component: FeaturesTwoComponent},
    {path: 'team', component: TeamComponent},
    {path: 'faq', component: FaqComponent,
    data: {
        title: 'Answering All Your Common Queries | Ayudadesk',
        description:'Read our FAQs before purchasing Ayudadesk healthcare helpdesk software. Call us or Email for detail information’s.',
        keywords:'Ayudadesk faqs, healthcare helpdesk software, best healthcare helpdesk software ',
        Ogurl:'https://www.ayudadesk.com/faq',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'Answering All Your Common Queries | Ayudadesk',
        OgDescription:'Read our FAQs before purchasing Ayudadesk healthcare helpdesk software. Call us or Email for detail information’s.',
        twittertitle:'Answering All Your Common Queries | Ayudadesk',
        twitterdescription:'Read our FAQs before purchasing Ayudadesk healthcare helpdesk software. Call us or Email for detail information’s.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/faq',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/faq",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/faq/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "Answering All Your Common Queries | Ayudadesk",

          "alternateName": "Ayudadesk faqs, healthcare helpdesk software, best healthcare helpdesk software ",

          "url": "https://www.ayudadesk.com/faq/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "Answering All Your Common Queries | Ayudadesk",

          "url": "https://www.ayudadesk.com/faq/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "Read our FAQs before purchasing Ayudadesk healthcare helpdesk software. Call us or Email for detail information’s.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'error', component: ErrorComponent},
    {path: 'log-in', component: LogInComponent},
    {path: 'free-trial', component: TryNowComponent},
    {path: 'blog', component: BlogGridComponent,
    data: {
        title: 'AyudaDesk Blogs - Healthcare Helpdesk Tool',
        description:'Get insight into latest trends and innovations happening in the healthcare, helpdesk software industry with our highly informative blogs.',
        keywords:'healthcare helpdesk blogs, healthcare helpdesk tool, healthcare helpdesk software blogs',
        Ogurl:'https://www.ayudadesk.com/blog',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'AyudaDesk Blogs - Healthcare Helpdesk Tool',
        OgDescription:'Get insight into latest trends and innovations happening in the healthcare, helpdesk software industry with our highly informative blogs.',
        twittertitle:'AyudaDesk Blogs - Healthcare Helpdesk Tool',
        twitterdescription:'Get insight into latest trends and innovations happening in the healthcare, helpdesk software industry with our highly informative blogs.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/blog',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/blog",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/blog/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "AyudaDesk Blogs - Healthcare Helpdesk Tool",

          "alternateName": "healthcare helpdesk blogs, healthcare helpdesk tool, healthcare helpdesk software blogs",

          "url": "https://www.ayudadesk.com/blog/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "AyudaDesk Blogs - Healthcare Helpdesk Tool",

          "url": "https://www.ayudadesk.com/blog/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "Get insight into latest trends and innovations happening in the healthcare, helpdesk software industry with our highly informative blogs.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'blog-2', component: BlogRightSidebarComponent},
    {path: 'helpdesk-enhance-healthcare-providers-efficiency', component: BlogDetailsComponent,
    data: {
      title: "How does SaaS helpdesk software enhance healthcare provider's efficiency and reliability?",
      description:'Technology offers a variety of options for selecting the right helpdesk software to provide individualized service, high-quality assistance, and high customer satisfaction. Customers perceive organizations as more competent....',
      keywords:'SaaS helpdesk software, SaaS healthcare helpdesk software, healthcare helpdesk software',
      Ogurl:'https://www.ayudadesk.com/helpdesk-enhance-healthcare-providers-efficiency',
      OgSiteName:'https://www.ayudadesk.com',
      OgImage:'https://www.ayudadesk.com/images/banner-img.png',
      OgTittle:"How does SaaS helpdesk software enhance healthcare provider's efficiency and reliability?",
      OgDescription:'Technology offers a variety of options for selecting the right helpdesk software to provide individualized service, high-quality assistance, and high customer satisfaction. Customers perceive organizations as more competent....',
      twittertitle:"How does SaaS helpdesk software enhance healthcare provider's efficiency and reliability?",
      twitterdescription:'Technology offers a variety of options for selecting the right helpdesk software to provide individualized service, high-quality assistance, and high customer satisfaction. Customers perceive organizations as more competent....',
      twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
      canonical:'/helpdesk-enhance-healthcare-providers-efficiency',
      script1:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "url": "https://www.ayudadesk.com/helpdesk-enhance-healthcare-providers-efficiency",

        "potentialAction": {

            "@type": "SearchAction",

            "target": "https://www.ayudadesk.com/helpdesk-enhance-healthcare-providers-efficiency/find?q={search_term_string}",

            "query-input": "required name=search_term_string"

        }

    },
      script2:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "name": "How does SaaS helpdesk software enhance healthcare provider's efficiency and reliability?",

        "alternateName": "SaaS helpdesk software, SaaS healthcare helpdesk software, healthcare helpdesk software",

        "url": "https://www.ayudadesk.com/helpdesk-enhance-healthcare-providers-efficiency/"

    },
      script3:{

        "@context": "https://schema.org",

        "@type": "Organization",

        "name": "How does SaaS helpdesk software enhance healthcare provider's efficiency and reliability?",

        "url": "https://www.ayudadesk.com/helpdesk-enhance-healthcare-providers-efficiency/",

        "email": "mailto:info@ayudadesk.com",

        "foundingDate": "2020",

        "description": "Technology offers a variety of options for selecting the right helpdesk software to provide individualized service, high-quality assistance, and high customer satisfaction. Customers perceive organizations as more competent....",

        "logo": "https://www.ayudadesk.com/assets/img/logo.png",

        "contactPoint": {

            "@type": "ContactPoint",

            "telephone": "+1 561-444-9174",

            "contactType": "sales",

            "contactOption": "Customer Service",

            "areaServed": "United States",

            "availableLanguage": "en"

        },

        "aggregateRating": {

            "@type": "AggregateRating",

            "ratingValue": "4.9",

            "reviewCount": "1407"

        },

        "sameAs": [

            " https://twitter.com/ayuda_desk",   

            " https://www.facebook.com/ayudadesk",

            " https://medium.com/@ayudadesk",

            " https://www.linkedin.com/company/ayudadesk/",

            " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

            " https://www.pinterest.com/ayudadesk/_saved/"

        ]

    }
    }},
    {path: 'best-collaborative-helpdesk-software-for-healthcare', component: Blog3Component,
    data: {
      title: "Tips for Selecting the Best Collaborative Helpdesk Software for Your Healthcare Company",
      description:'Collaborative helpdesk software is both a lifesaver and a strategic investment because it allows us to focus on our core services without sacrificing customer service level or satisfaction. Ayudadesk is designed for agents, vendors, and internal teams.',
      keywords:'collaborative healthcare helpdesk software, best collaborative healthcare helpdesk software, how to select best healthcare helpdesk software',
      Ogurl:'https://www.ayudadesk.com/best-collaborative-helpdesk-software-for-healthcare',
      OgSiteName:'https://www.ayudadesk.com',
      OgImage:'https://www.ayudadesk.com/images/banner-img.png',
      OgTittle:"Tips for Selecting the Best Collaborative Helpdesk Software for Your Healthcare Company",
      OgDescription:'Collaborative helpdesk software is both a lifesaver and a strategic investment because it allows us to focus on our core services without sacrificing customer service level or satisfaction. Ayudadesk is designed for agents, vendors, and internal teams.',
      twittertitle:"Tips for Selecting the Best Collaborative Helpdesk Software for Your Healthcare Company",
      twitterdescription:'Collaborative helpdesk software is both a lifesaver and a strategic investment because it allows us to focus on our core services without sacrificing customer service level or satisfaction. Ayudadesk is designed for agents, vendors, and internal teams.',
      twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
      canonical:'/best-collaborative-helpdesk-software-for-healthcare',
      script1:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "url": "https://www.ayudadesk.com/best-collaborative-helpdesk-software-for-healthcare",

        "potentialAction": {

            "@type": "SearchAction",

            "target": "https://www.ayudadesk.com/best-collaborative-helpdesk-software-for-healthcare/find?q={search_term_string}",

            "query-input": "required name=search_term_string"

        }

    },
      script2:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "name": "Tips for Selecting the Best Collaborative Helpdesk Software for Your Healthcare Company",

        "alternateName": "collaborative healthcare helpdesk software, best collaborative healthcare helpdesk software, how to select best healthcare helpdesk software",

        "url": "https://www.ayudadesk.com/best-collaborative-helpdesk-software-for-healthcare/"

    },
      script3:{

        "@context": "https://schema.org",

        "@type": "Organization",

        "name": "Tips for Selecting the Best Collaborative Helpdesk Software for Your Healthcare Company",

        "url": "https://www.ayudadesk.com/best-collaborative-helpdesk-software-for-healthcare/",

        "email": "mailto:info@ayudadesk.com",

        "foundingDate": "2020",

        "description": "Collaborative helpdesk software is both a lifesaver and a strategic investment because it allows us to focus on our core services without sacrificing customer service level or satisfaction. Ayudadesk is designed for agents, vendors, and internal teams.",

        "logo": "https://www.ayudadesk.com/assets/img/logo.png",

        "contactPoint": {

            "@type": "ContactPoint",

            "telephone": "+1 561-444-9174",

            "contactType": "sales",

            "contactOption": "Customer Service",

            "areaServed": "United States",

            "availableLanguage": "en"

        },

        "aggregateRating": {

            "@type": "AggregateRating",

            "ratingValue": "4.9",

            "reviewCount": "1407"

        },

        "sameAs": [

            " https://twitter.com/ayuda_desk",   

            " https://www.facebook.com/ayudadesk",

            " https://medium.com/@ayudadesk",

            " https://www.linkedin.com/company/ayudadesk/",

            " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

            " https://www.pinterest.com/ayudadesk/_saved/"

        ]

    }
    }},
    {path: 'importance-of-customer-service-in-healthcare-industry', component: Blog2Component,
    data: {
      title: "The Importance of Great Customer Service in the Healthcare Industry",
      description:'Customer service skills in healthcare industry, in particular, frequently include a high level of flexibility, as they are frequently required to work after office hours and on weekends as well.',
      keywords:'Importance of great customer service, importance of customer service in healthcare industry, healthcare helpdesk software, healthcare helpdesk tool',
      Ogurl:'https://www.ayudadesk.com/importance-of-customer-service-in-healthcare-industry',
      OgSiteName:'https://www.ayudadesk.com',
      OgImage:'https://www.ayudadesk.com/images/importance-of-customer-service-in-healthcare-industry.png',
      OgTittle:"The Importance of Great Customer Service in the Healthcare Industry",
      OgDescription:'Customer service skills in healthcare industry, in particular, frequently include a high level of flexibility, as they are frequently required to work after office hours and on weekends as well.',
      twittertitle:"The Importance of Great Customer Service in the Healthcare Industry",
      twitterdescription:'Customer service skills in healthcare industry, in particular, frequently include a high level of flexibility, as they are frequently required to work after office hours and on weekends as well.',
      twitterImage:'https://www.ayudadesk.com/images/importance-of-customer-service-in-healthcare-industry.png',
      canonical:'/importance-of-customer-service-in-healthcare-industry',
      script1:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "url": "https://www.ayudadesk.com/importance-of-customer-service-in-healthcare-industry",

        "potentialAction": {

            "@type": "SearchAction",

            "target": "https://www.ayudadesk.com/importance-of-customer-service-in-healthcare-industry/find?q={search_term_string}",

            "query-input": "required name=search_term_string"

        }

    },
      script2:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "name": "The Importance of Great Customer Service in the Healthcare Industry",

        "alternateName": "Importance of great customer service, importance of customer service in healthcare industry, healthcare helpdesk software, healthcare helpdesk tool",

        "url": "https://www.ayudadesk.com/importance-of-customer-service-in-healthcare-industry/"

    },
      script3:{

        "@context": "https://schema.org",

        "@type": "Organization",

        "name": "The Importance of Great Customer Service in the Healthcare Industry",

        "url": "https://www.ayudadesk.com/importance-of-customer-service-in-healthcare-industry/",

        "email": "mailto:info@ayudadesk.com",

        "foundingDate": "2020",

        "description": "Customer service skills in healthcare industry, in particular, frequently include a high level of flexibility, as they are frequently required to work after office hours and on weekends as well.",

        "logo": "https://www.ayudadesk.com/assets/img/logo.png",

        "contactPoint": {

            "@type": "ContactPoint",

            "telephone": "+1 561-444-9174",

            "contactType": "sales",

            "contactOption": "Customer Service",

            "areaServed": "United States",

            "availableLanguage": "en"

        },

        "aggregateRating": {

            "@type": "AggregateRating",

            "ratingValue": "4.9",

            "reviewCount": "1407"

        },

        "sameAs": [

            " https://twitter.com/ayuda_desk",   

            " https://www.facebook.com/ayudadesk",

            " https://medium.com/@ayudadesk",

            " https://www.linkedin.com/company/ayudadesk/",

            " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

            " https://www.pinterest.com/ayudadesk/_saved/"

        ]

    }
    }},
    {path: 'hipaa-compliant-help-desk-software', component: Blog4Component,
    data: {
      title: "You Can Rely On HIPAA-Compliant Help Desk Software",
      description:'HIPAA-compliant help desk software allows businesses to reply to users more quickly and securely. As a result, it is an excellent ticketing system for enterprises.',
      keywords:'hipaa compliant help desk software, hipaa healthcare helpdesk software, healthcare helpdesk software',
      Ogurl:'https://www.ayudadesk.com/hipaa-compliant-help-desk-software',
      OgSiteName:'https://www.ayudadesk.com',
      OgImage:'https://www.ayudadesk.com/images/banner-img.png',
      OgTittle:"You Can Rely On HIPAA-Compliant Help Desk Software",
      OgDescription:'HIPAA-compliant help desk software allows businesses to reply to users more quickly and securely. As a result, it is an excellent ticketing system for enterprises.',
      twittertitle:"You Can Rely On HIPAA-Compliant Help Desk Software",
      twitterdescription:'HIPAA-compliant help desk software allows businesses to reply to users more quickly and securely. As a result, it is an excellent ticketing system for enterprises.',
      twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
      canonical:'/hipaa-compliant-help-desk-software',
      script1:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "url": "https://www.ayudadesk.com/hipaa-compliant-help-desk-software",

        "potentialAction": {

            "@type": "SearchAction",

            "target": "https://www.ayudadesk.com/hipaa-compliant-help-desk-software/find?q={search_term_string}",

            "query-input": "required name=search_term_string"

        }

    },
      script2:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "name": "You Can Rely On HIPAA-Compliant Help Desk Software",

        "alternateName": "hipaa compliant help desk software, hipaa healthcare helpdesk software, healthcare helpdesk software",

        "url": "https://www.ayudadesk.com/hipaa-compliant-help-desk-software/"

    },
      script3:{

        "@context": "https://schema.org",

        "@type": "Organization",

        "name": "You Can Rely On HIPAA-Compliant Help Desk Software",

        "url": "https://www.ayudadesk.com/hipaa-compliant-help-desk-software/",

        "email": "mailto:info@ayudadesk.com",

        "foundingDate": "2020",

        "description": "HIPAA-compliant help desk software allows businesses to reply to users more quickly and securely. As a result, it is an excellent ticketing system for enterprises.",

        "logo": "https://www.ayudadesk.com/assets/img/logo.png",

        "contactPoint": {

            "@type": "ContactPoint",

            "telephone": "+1 561-444-9174",

            "contactType": "sales",

            "contactOption": "Customer Service",

            "areaServed": "United States",

            "availableLanguage": "en"

        },

        "aggregateRating": {

            "@type": "AggregateRating",

            "ratingValue": "4.9",

            "reviewCount": "1407"

        },

        "sameAs": [

            " https://twitter.com/ayuda_desk",   

            " https://www.facebook.com/ayudadesk",

            " https://medium.com/@ayudadesk",

            " https://www.linkedin.com/company/ayudadesk/",

            " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

            " https://www.pinterest.com/ayudadesk/_saved/"

        ]

    }
    }},
    {path: 'healthcare-helpdesk-software', component: Blog5Component,
    data: {
      title: "An Ultimate Guide to Helpdesk Software in Healthcare",
      description:'Know how helpdesk software works in the healthcare field, its types, features, benefits, industry use cases, and more.',
      keywords:'helpdesk software in healthcare, healthcare helpdesk software',
      Ogurl:'https://www.ayudadesk.com/healthcare-helpdesk-software',
      OgSiteName:'https://www.ayudadesk.com',
      OgImage:'https://www.ayudadesk.com/images/healthcare-helpdesk-software.png',
      OgTittle:"An Ultimate Guide to Helpdesk Software in Healthcare",
      OgDescription:'Know how helpdesk software works in the healthcare field, its types, features, benefits, industry use cases, and more.',
      twittertitle:"An Ultimate Guide to Helpdesk Software in Healthcare",
      twitterdescription:'Know how helpdesk software works in the healthcare field, its types, features, benefits, industry use cases, and more.',
      twitterImage:'https://www.ayudadesk.com/images/healthcare-helpdesk-software.png',
      canonical:'/healthcare-helpdesk-software',
      script1:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "url": "https://www.ayudadesk.com/healthcare-helpdesk-software",

        "potentialAction": {

            "@type": "SearchAction",

            "target": "https://www.ayudadesk.com/healthcare-helpdesk-software/find?q={search_term_string}",

            "query-input": "required name=search_term_string"

        }

    },
      script2:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "name": "An Ultimate Guide to Helpdesk Software in Healthcare",

        "alternateName": "helpdesk software in healthcare, healthcare helpdesk software",

        "url": "https://www.ayudadesk.com/healthcare-helpdesk-software/"

    },
      script3:{

        "@context": "https://schema.org",

        "@type": "Organization",

        "name": "An Ultimate Guide to Helpdesk Software in Healthcare",

        "url": "https://www.ayudadesk.com/healthcare-helpdesk-software/",

        "email": "mailto:info@ayudadesk.com",

        "foundingDate": "2020",

        "description": "Know how helpdesk software works in the healthcare field, its types, features, benefits, industry use cases, and more.",

        "logo": "https://www.ayudadesk.com/assets/img/logo.png",

        "contactPoint": {

            "@type": "ContactPoint",

            "telephone": "+1 561-444-9174",

            "contactType": "sales",

            "contactOption": "Customer Service",

            "areaServed": "United States",

            "availableLanguage": "en"

        },

        "aggregateRating": {

            "@type": "AggregateRating",

            "ratingValue": "4.9",

            "reviewCount": "1407"

        },

        "sameAs": [

            " https://twitter.com/ayuda_desk",   

            " https://www.facebook.com/ayudadesk",

            " https://medium.com/@ayudadesk",

            " https://www.linkedin.com/company/ayudadesk/",

            " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

            " https://www.pinterest.com/ayudadesk/_saved/"

        ]

    }
    }},
    {path: 'why-helpdesk-software', component: Blog6Component,
    data: {
      title: "9 Reasons Why Helpdesk Software is Must for Healthcare Industry",
      description:'Do you ever think about implementing helpdesk software for your healthcare business? This article is covering the benefits of using helpdesk software in the healthcare industry. Read now.',
      keywords:'helpdesk software in the healthcare business, helpdesk software in the healthcare industry',
      Ogurl:'https://www.ayudadesk.com/why-helpdesk-software',
      OgSiteName:'https://www.ayudadesk.com',
      OgImage:'https://www.ayudadesk.com/images/why-helpdesk-software.png',
      OgTittle:"9 Reasons Why Helpdesk Software is Must for Healthcare Industry",
      OgDescription:'Do you ever think about implementing helpdesk software for your healthcare business? This article is covering the benefits of using helpdesk software in the healthcare industry. Read now.',
      twittertitle:"9 Reasons Why Helpdesk Software is Must for Healthcare Industry",
      twitterdescription:'Do you ever think about implementing helpdesk software for your healthcare business? This article is covering the benefits of using helpdesk software in the healthcare industry. Read now.',
      twitterImage:'https://www.ayudadesk.com/images/why-helpdesk-software.png',
      canonical:'/why-helpdesk-software',
      script1:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "url": "https://www.ayudadesk.com/why-helpdesk-software",

        "potentialAction": {

            "@type": "SearchAction",

            "target": "https://www.ayudadesk.com/why-helpdesk-software/find?q={search_term_string}",

            "query-input": "required name=search_term_string"

        }

    },
      script2:{

        "@context": "http://schema.org",

        "@type": "WebSite",

        "name": "9 Reasons Why Helpdesk Software is Must for Healthcare Industry",

        "alternateName": "helpdesk software in the healthcare business, helpdesk software in the healthcare industry",

        "url": "https://www.ayudadesk.com/why-helpdesk-software/"

    },
      script3:{

        "@context": "https://schema.org",

        "@type": "Organization",

        "name": "9 Reasons Why Helpdesk Software is Must for Healthcare Industry",

        "url": "https://www.ayudadesk.com/why-helpdesk-software/",

        "email": "mailto:info@ayudadesk.com",

        "foundingDate": "2020",

        "description": "Do you ever think about implementing helpdesk software for your healthcare business? This article is covering the benefits of using helpdesk software in the healthcare industry. Read now.",

        "logo": "https://www.ayudadesk.com/assets/img/logo.png",

        "contactPoint": {

            "@type": "ContactPoint",

            "telephone": "+1 561-444-9174",

            "contactType": "sales",

            "contactOption": "Customer Service",

            "areaServed": "United States",

            "availableLanguage": "en"

        },

        "aggregateRating": {

            "@type": "AggregateRating",

            "ratingValue": "4.9",

            "reviewCount": "1407"

        },

        "sameAs": [

            " https://twitter.com/ayuda_desk",   

            " https://www.facebook.com/ayudadesk",

            " https://medium.com/@ayudadesk",

            " https://www.linkedin.com/company/ayudadesk/",

            " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

            " https://www.pinterest.com/ayudadesk/_saved/"

        ]

    }
    }},
    {path: 'contact', component: ContactComponent,
    data: {
        title: 'Contact Us - AyudaDesk Helpdesk Software for Healthcare',
        description:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace. Communicate, collaborate and manage your teams, vendors and clients, from anywhere and on any device. Call us for a product demo.',
        keywords:'healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software',
        Ogurl:'https://www.ayudadesk.com/contact',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'Contact Us - AyudaDesk Helpdesk Software for Healthcare',
        OgDescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace. Communicate, collaborate and manage your teams, vendors and clients, from anywhere and on any device. Call us for a product demo.',
        twittertitle:'Contact Us - AyudaDesk Helpdesk Software for Healthcare',
        twitterdescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace. Communicate, collaborate and manage your teams, vendors and clients, from anywhere and on any device. Call us for a product demo.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/contact',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/contact",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/contact/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "Contact Us - AyudaDesk Helpdesk Software for Healthcare",

          "alternateName": "healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software",

          "url": "https://www.ayudadesk.com/contact/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "Contact Us - AyudaDesk Helpdesk Software for Healthcare",

          "url": "https://www.ayudadesk.com/contact/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace. Communicate, collaborate and manage your teams, vendors and clients, from anywhere and on any device. Call us for a product demo.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'privacy-policy', component: PrivacyPolicyComponent,
    data: {
        title: 'AyudaDesk Privacy Policy',
        description:'AyudaDesk, Inc. is committed to protecting your privacy. We assure that the information provided when using this website such as your name, email, phone number and any other details will only be used to understand your needs and provide you with better service.',
        keywords:'ayudaDesk privacy policy, AyudaDesk policies, ayudaDesk healthcare ticketing system',
        Ogurl:'https://www.ayudadesk.com/privacy-policy',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'AyudaDesk Privacy Policy',
        OgDescription:'AyudaDesk, Inc. is committed to protecting your privacy. We assure that the information provided when using this website such as your name, email, phone number and any other details will only be used to understand your needs and provide you with better service.',
        twittertitle:'AyudaDesk Privacy Policy',
        twitterdescription:'AyudaDesk, Inc. is committed to protecting your privacy. We assure that the information provided when using this website such as your name, email, phone number and any other details will only be used to understand your needs and provide you with better service.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/privacy-policy',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/privacy-policy",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/privacy-policy/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "AyudaDesk Privacy Policy",

          "alternateName": "ayudaDesk privacy policy, AyudaDesk policies, ayudaDesk healthcare ticketing system",

          "url": "https://www.ayudadesk.com/privacy-policy/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "AyudaDesk Privacy Policy",

          "url": "https://www.ayudadesk.com/privacy-policy/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "AyudaDesk, Inc. is committed to protecting your privacy. We assure that the information provided when using this website such as your name, email, phone number and any other details will only be used to understand your needs and provide you with better service.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'term-and-conditions', component: TermAndConditionsComponent,
    data: {
        title: 'AyudaDesk - Terms and Conditions',
        description:'Use of the Services provided by AyudaDesk shall entail your agreement to be bound by the following Terms of Use. AyudaDesk is not responsible for any damages resulting from the use of this website by anyone.',
        keywords:'ayudadesk terms and conditions, ayudadesk policies, ayudadesk healthcare ticketing software',
        Ogurl:'https://www.ayudadesk.com/term-and-conditions',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'AyudaDesk - Terms and Conditions',
        OgDescription:'Use of the Services provided by AyudaDesk shall entail your agreement to be bound by the following Terms of Use. AyudaDesk is not responsible for any damages resulting from the use of this website by anyone.',
        twittertitle:'AyudaDesk - Terms and Conditions',
        twitterdescription:'Use of the Services provided by AyudaDesk shall entail your agreement to be bound by the following Terms of Use. AyudaDesk is not responsible for any damages resulting from the use of this website by anyone.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/term-and-conditions',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/term-and-conditions",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/term-and-conditions/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "AyudaDesk - Terms and Conditions",

          "alternateName": "ayudadesk terms and conditions, ayudadesk policies, ayudadesk healthcare ticketing software",

          "url": "https://www.ayudadesk.com/term-and-conditions/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "AyudaDesk - Terms and Conditions",

          "url": "https://www.ayudadesk.com/term-and-conditions/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "Use of the Services provided by AyudaDesk shall entail your agreement to be bound by the following Terms of Use. AyudaDesk is not responsible for any damages resulting from the use of this website by anyone.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'request-a-demo', component: RequestADemoComponent,
    data: {
        title: 'Helpdesk Software for Healthcare - AyudaDesk',
        description:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        keywords:'healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software, healthcare help desk ticketing tool, healthcare Service Desk Software, help desk software for Healthcare, ticketing system for Healthcare, Healthcare Helpdesk Solutions',
        Ogurl:'https://www.ayudadesk.com/request-a-demo',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'Helpdesk Software for Healthcare - AyudaDesk',
        OgDescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        twittertitle:'Helpdesk Software for Healthcare - AyudaDesk',
        twitterdescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/request-a-demo',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/request-a-demo",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/request-a-demo/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "Helpdesk Software for Healthcare - AyudaDesk",

          "alternateName": "healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software, healthcare help desk ticketing tool, healthcare Service Desk Software, help desk software for Healthcare, ticketing system for Healthcare, Healthcare Helpdesk Solutions",

          "url": "https://www.ayudadesk.com/request-a-demo/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "Helpdesk Software for Healthcare - AyudaDesk",

          "url": "https://www.ayudadesk.com/request-a-demo/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
    {path: 'ayudadesk-at-himss22', component: HylandBoothComponent,
    data: {
        title: 'Helpdesk Software for Healthcare - AyudaDesk',
        description:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        keywords:'healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software, healthcare help desk ticketing tool, healthcare Service Desk Software, help desk software for Healthcare, ticketing system for Healthcare, Healthcare Helpdesk Solutions',
        Ogurl:'https://www.ayudadesk.com/ayudadesk-at-himss22',
        OgSiteName:'https://www.ayudadesk.com',
        OgImage:'https://www.ayudadesk.com/images/banner-img.png',
        OgTittle:'Helpdesk Software for Healthcare - AyudaDesk',
        OgDescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        twittertitle:'Helpdesk Software for Healthcare - AyudaDesk',
        twitterdescription:'AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.',
        twitterImage:'https://www.ayudadesk.com/images/banner-img.png',
        canonical:'/ayudadesk-at-himss22',
        script1:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "url": "https://www.ayudadesk.com/ayudadesk-at-himss22",

          "potentialAction": {

              "@type": "SearchAction",

              "target": "https://www.ayudadesk.com/ayudadesk-at-himss22/find?q={search_term_string}",

              "query-input": "required name=search_term_string"

          }

      },
        script2:{

          "@context": "http://schema.org",

          "@type": "WebSite",

          "name": "Helpdesk Software for Healthcare - AyudaDesk",

          "alternateName": "healthcare ticketing system, healthcare ticketing software, healthcare help desk software, healthcare customer service software, healthcare help desk management software, healthcare help desk ticketing tool, healthcare Service Desk Software, help desk software for Healthcare, ticketing system for Healthcare, Healthcare Helpdesk Solutions",

          "url": "https://www.ayudadesk.com/ayudadesk-at-himss22/"

      },
        script3:{

          "@context": "https://schema.org",

          "@type": "Organization",

          "name": "Helpdesk Software for Healthcare - AyudaDesk",

          "url": "https://www.ayudadesk.com/ayudadesk-at-himss22/",

          "email": "mailto:info@ayudadesk.com",

          "foundingDate": "2020",

          "description": "AyudaDesk is an innovative and intuitive HIPAA compliant helpdesk designed for the modern-day healthcare workplace.",

          "logo": "https://www.ayudadesk.com/assets/img/logo.png",

          "contactPoint": {

              "@type": "ContactPoint",

              "telephone": "+1 561-444-9174",

              "contactType": "sales",

              "contactOption": "Customer Service",

              "areaServed": "United States",

              "availableLanguage": "en"

          },

          "aggregateRating": {

              "@type": "AggregateRating",

              "ratingValue": "4.9",

              "reviewCount": "1407"

          },

          "sameAs": [

              " https://twitter.com/ayuda_desk",   

              " https://www.facebook.com/ayudadesk",

              " https://medium.com/@ayudadesk",

              " https://www.linkedin.com/company/ayudadesk/",

              " https://www.youtube.com/channel/UCPB8LgYI0jSQwY4LKC3KhLQ",

              " https://www.pinterest.com/ayudadesk/_saved/"

          ]

      }
      } },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
