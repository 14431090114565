<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>HIMSS22</h2>
        </div>
    </div>
</div>
<section class="about-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="text-center">
                    <a href="https://www.himss.org/" target="_blank"><img src="assets/img/himss22.PNG"></a>
                 </div>
                 <div>
                    <p>Schedule a one-on-one demo and Q&A or join us at booth #8140-66.</p>
                 </div>
                
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12" >
                <div class="contact-form">
                    <h4>Meeting Request for a Demo at HIMSS22</h4>
                    <form id="hylandcontactForm" [formGroup]="hylandcontactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Name">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Required</div>
                                        <div *ngIf="f.email.errors.email">Must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" formControlName="phone_number" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }" placeholder="(000) 000-0000" mask="(000) 000-0000"
                                    class="form-control">
                                    <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone_number.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" formControlName="msg_subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.msg_subject.errors }" placeholder="Company">
                                    <div *ngIf="submitted && f.msg_subject.errors" class="invalid-feedback">
                                        <div *ngIf="f.msg_subject.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="6" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" placeholder="Message"></textarea>
                                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"><i class='bx bxs-paper-plane' style="color: #ffffff;"></i>Send Message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(72,191,145,0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>