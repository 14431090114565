<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Free Trial</h2>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <!-- <div class="row">
            <div class="container">
                <div id="iframe_container" class="iframe-container">

                    <iframe 
                     id="1"
                     name="my_frame"
                     title="Title"
                     src="http://18.189.33.46/ayudadesk/#/auth/signup"
                     align="center"
                     scrolling="yes"   class="iframe-responsive"
                 
                    ></iframe>
                 
                 </div>
                 
                 
            </div>
        </div> -->
        <form id="signupform" [formGroup]="signupform" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <h2>Exclusive Helpdesk for Healthcare</h2>
              <h3 style="margin-top: 25px;">14 days trial includes:</h3>
              <ul class="try-now-features" style="color: #4a6f8a;">
                <li><i class='bx bxs-badge-check'></i> Full Access</li>
                <li><i class='bx bxs-badge-check'></i> Unlimited Agents & Vendors </li>
                <li><i class='bx bxs-badge-check'></i> Unlimited Client Portals </li>
                <li><i class='bx bxs-badge-check'></i> Unlimited Forms & Reviews </li>
                <li><i class='bx bxs-badge-check'></i> Unlimited Storage </li>
                <li><i class='bx bxs-badge-check'></i> No Credit Card needed</li>
             </ul>
            </div>
            <div class="col-lg-6 col-md-6 signup-form">
              <h2 class="text-center">Sign Up</h2><br>
              <div class="form-group">
                  <!-- <p>Email</p> -->
                  <input type="email" name="email" id="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Required</div>
                      <div *ngIf="f.email.errors.email">Must be a valid email address</div>
                  </div>
              </div>
              <div class="form-group">
                <!-- <p>First Name</p> -->
                  <input type="text" name="firstname" id="firstname" class="form-control" formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" placeholder="First Name">
                  <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                      <div *ngIf="f.firstname.errors.required">Required</div>
                  </div>
              </div>
              <div class="form-group">
                <!-- <p>Last Name</p> -->
                  <input type="text" name="lastname" id="lastname" class="form-control" formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" placeholder="Last Name">
                  <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                      <div *ngIf="f.lastname.errors.required">Required</div>
                  </div>
              </div>
              <div class="form-group">
                <!-- <p>Company Name</p> -->
                  <input type="text" name="companyname" id="companyname" class="form-control" formControlName="companyname" [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }" placeholder="Company Name">
                  <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                      <div *ngIf="f.companyname.errors.required">Required</div>
                  </div>
              </div>
              <div class="form-group">
                    <input type="text" name="phone" id="phone" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Phone" mask="(000) 000-0000">
                  <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                      <div *ngIf="f.phone.errors.required">Required</div>
                  </div>
              </div>
              <div class="text-center">
                <button type="submit" class="default-btn"><i class='bx bxs-paper-plane' style="color: #ffffff;"></i>Sign Up<span></span></button>
            </div>
          </div>
             
          </div>
      </form>
        
    </div>
</section>
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>
<ngx-spinner bdColor="rgba(72,191,145,0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>