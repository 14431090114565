import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app.settings';

@Injectable({
  providedIn: 'root'
})
export class TryNowService {
  constructor(private http: HttpClient) {
}
  public signup(userParam:any): Observable<any> {
      return this.http.post(AppSettings.REST_ENDPOINT_trynow, userParam);
    }
}
