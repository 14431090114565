<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Features</h2>
        </div>
    </div>
</div>

<section class="features-area pt-100 ptb-100">
    
    <div class="service-details pb-70">
        <div class="container">
            <div class="">
                <h2>Modernize your helpdesk</h2>
                <p>Streamline, automate and manage your support process in one easy to use HIPAA compliant helpdesk that requires little to no training.</p>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".1s">
                    <div class="icon">
                        <i class='bx bx-wrench'></i>
                    </div>
                    <h3>Easy Setup</h3>
                    <p>No programming or IT knowledge needed. Multiple admin levels for each group to continue to improve their support process.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bx-shape-circle'></i>
                    </div>
                    <h3>Automation</h3>
                    <p>Automate ticket assignment directly to a team or agent for faster routing. Automate responses based on forms or tags selected.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".3s">
                    <div class="icon">
                        <i class='bx bx-link'></i>
                    </div>
                    <h3>Email Integration</h3>
                    <p>Open tickets using email workflows or easily integrate your existing support email workflows with automatic user matching.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>Vendor Access</h3>
                    <p>Consolidate communication between your clients, agents and vendors for faster response and improved collaboration.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".5s">
                    <div class="icon">
                        <i class='bx bx-log-in'></i>
                    </div>
                    <h3>Client Portal</h3>
                    <p>Unlimited web portals for each client to open, track and update tickets. Web portal, email and mobile app push notifications.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-features-box wow zoomIn" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-star'></i>
                    </div>
                    <h3>Client Ratings</h3>
                    <p>Improve your customer service by capturing individual ticket written reviews and 5-star ratings for your agents and vendors.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>AyudaDesk</span></h2>
        </div>
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img6.jpg" alt="image">
                    <h3>Michel John</h3>
                    <span>CEO at Envato</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img7.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at EnvyTheme</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img8.jpg" alt="image">
                    <h3>James Andy</h3>
                    <span>CEO at 3S</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img8.jpg" alt="image">
                    <h3>James Andy</h3>
                    <span>CEO at 3S</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img7.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at EnvyTheme</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>
-->
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>