import { Injectable, Optional, RendererFactory2, ViewEncapsulation, Inject, Renderer2 } from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
@Injectable({
    providedIn: 'root' // Add this to ensure your SEO service will be app-wide available
})
export class SEOService {
  private renderer2: Renderer2;
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private dom, @Inject(DOCUMENT) private _document: Document, rendererFactory: RendererFactory2,) { 
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }
  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords });
  }


//   updateOgType(OgType: string) {
//     this.meta.updateTag({ name: 'og:type', content: OgType });
//   }
  updateOgUrl(Ogurl: string) {
    this.meta.updateTag({ name: 'og:url', content: Ogurl });
  }
  updateOgSiteName(OgSiteName: string) {
    this.meta.updateTag({ name: 'og:site_name', content: OgSiteName });
  }
  updateOgImage(OgImage: string) {
    this.meta.updateTag({ name: 'og:image', content: OgImage });
  }
  updateOgTittle(OgTittle: string) {
    this.meta.updateTag({ name: 'og:title', content: OgTittle });
  }
  updateOgDescription(OgDescription: string) {
    this.meta.updateTag({ name: 'og:description', content: OgDescription });
  }

  

//   updateTwitterCard(twitterCard: string) {
//     this.meta.updateTag({ name: 'twitter:card', content: twitterCard });
//   }
  updateTwitterTittle(twittertitle: string) {
    this.meta.updateTag({ name: 'twitter:title', content: twittertitle });
  }
  updateTwitterDescription(twitterdescription: string) {
    this.meta.updateTag({ name: 'twitter:description', content: twitterdescription });
  }
  updateTwitterImage(twitterImage: string) {
    this.meta.updateTag({ name: 'twitter:image', content: twitterImage });
  }

/**
 * Inject the State into the bottom of the <head>
 */
 updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }


  public setJsonLd(data: any, id:any): void {
    let elem=document.getElementById(id);
    if(elem){
      this.renderer2.removeChild(this._document.body,elem);
    }
    let script = this.renderer2.createElement('script');
    script.type = 'application/ld+json';
    script.id = id;
    script.text = `${JSON.stringify(data)}`;
    this.renderer2.appendChild(this._document.body, script); 
}
}
