import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import { AppSettings } from 'src/app/app.settings';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }
  
  public contactDetails(user): Observable<any>{
    //return this.http.post('http://18.189.33.46/ayapi/requestEmail', user); 
    return this.http.post(AppSettings.REST_ENDPOINT_requstdemo, user); 
  }

}
