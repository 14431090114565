<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/why-helpdesk-software"><img src="assets/img/why-helpdesk-software.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Apr 28, 2022</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/why-helpdesk-software">9 Reasons Why Helpdesk Software is Must for Healthcare Industry</a></h3>
                        
                        <!-- <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Jeyanthi Rajaselvarasu
                                </h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/healthcare-helpdesk-software"><img src="assets/img/healthcare-helpdesk-software.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Mar 28, 2022</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/healthcare-helpdesk-software">An Ultimate Guide to Helpdesk Software in Healthcare</a></h3>
                        
                        <!-- <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Jeyanthi Rajaselvarasu
                                </h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/hipaa-compliant-help-desk-software"><img src="assets/img/hipaa-compliant-help-desk-software.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Feb 25, 2022</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/hipaa-compliant-help-desk-software">You Can Rely On HIPAA-Compliant Help Desk Software</a></h3>
                        <p>&nbsp;</p>
                        <!-- <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Jeyanthi Rajaselvarasu
                                </h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/helpdesk-enhance-healthcare-providers-efficiency"><img src="assets/img/ayudadesk-blog-2.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Jan 28, 2022</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/helpdesk-enhance-healthcare-providers-efficiency">How does SaaS helpdesk software enhance healthcare provider's efficiency and reliability?</a></h3>
                        
                        <!-- <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Jeyanthi Rajaselvarasu
                                </h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/importance-of-customer-service-in-healthcare-industry"><img src="assets/img/importance-of-customer-service-in-healthcare-industry.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Dec 05, 2021</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/importance-of-customer-service-in-healthcare-industry">The Importance of Great Customer Service in the Healthcare Industry</a></h3>
                        <h3>&nbsp;</h3>
                        <!-- <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img2.jpg" alt="image">
                                <h6>Jeyanthi Rajaselvarasu</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/best-collaborative-helpdesk-software-for-healthcare"><img src="assets/img/ayudadesk-blog-4.png" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Jun 14, 2021</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/best-collaborative-helpdesk-software-for-healthcare">Tips for Selecting the Best Collaborative Helpdesk Software</a></h3>
                        <h3>&nbsp;</h3>
                    </div>
                </div>
            </div>
            <!--
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img4.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2019</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Configure Redux Into Your WordPress Theme</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img2.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img5.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2019</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">How To Setup Redux In React Next Application</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img6.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2019</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">How To Resubmit Rejected Item Into ThemeForest?</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img1.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img7.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2019</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">How To The Active Menu Based On URL In Next.JS?</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img8.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2019</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">How To Create A Responsive Popup Gallery?</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img9.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2019</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">WP Custom Post Register & Query</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img1.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a href="#" class="prev page-numbers"><i class='bx bxs-arrow-to-left'></i></a>
                    <a href="#" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a href="#" class="page-numbers">3</a>
                    <a href="#" class="page-numbers">4</a>
                    <a href="#" class="next page-numbers"><i class='bx bxs-arrow-to-right'></i></a>
                </div>
            </div>
            -->
        </div>
    </div>
</section>

<!-- <section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content">
            <h2>We provide HIPAA complaint helpdesk support platform</h2>
            <p>Simplify the support team’s work to satisfy all healthcare professionals with one workspace on a HIPAA complaint platform.            </p>
            
        </div>
    </div> -->
    <!--<div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>-->
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>