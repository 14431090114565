import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';  
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from './SEOService.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
       Location,{
           provide: LocationStrategy,
           useClass: PathLocationStrategy
       }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router,
        private titleService: Title,  
        private metaTagService: Meta,
        private _seoService: SEOService,
        private activatedRoute: ActivatedRoute
        ) {
    }

    ngOnInit(){
        const hostName="https://www.ayudadesk.com"
        this.recallJsFuntions();
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
              while (route.firstChild) route = route.firstChild;
              return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
           )
           .subscribe((event) => {
             console.log(event.canonical);
             this._seoService.updateTitle(event['title']);
             this._seoService.updateDescription(event['title'] + event['description']);
             this._seoService.updateKeywords(event['keywords']);
             //this._seoService.updateOgType(event['OgType']);
             this._seoService.updateOgUrl(event['Ogurl']);
             this._seoService.updateOgSiteName(event['OgSiteName']);
             this._seoService.updateOgImage(event['OgImage']);
             this._seoService.updateOgTittle(event['OgTittle']);
             this._seoService.updateOgDescription(event['OgDescription']);
             //this._seoService.updateTwitterCard(event['twitterCard']);
             this._seoService.updateTwitterTittle(event['twittertitle']);
             this._seoService.updateTwitterDescription(event['twitterdescription']);
             this._seoService.updateTwitterImage(event['twitterImage']);
            // this._seoService.addTag( { rel: 'canonical', href: 'url here'} );
            if (event.canonical) {
                this._seoService.updateCanonicalUrl(hostName+event.canonical);
              } else {
                this._seoService.updateCanonicalUrl(hostName+this.router.url);
              }
            
            this._seoService.setJsonLd(event['script1'],'s1');
            this._seoService.setJsonLd(event['script2'],'s2');
            this._seoService.setJsonLd(event['script3'],'s3');
           }); 
           
          
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader-area').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $.getScript('../assets/js/conversation.js');
            $('.preloader-area').fadeOut('slow');
            this.location = this.router.url;
            console.log(this.location);
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}