<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content" style="text-align: left;">
                    <!--<span class="sub-title">How policy are</span>
                    <h2>Privacy Policy</h2>-->
                    <p>AyudaDesk, Inc. is committed to protecting your privacy. We assure that the information provided when using this website such as your name, email, phone number and any other details will only be used to understand your needs and provide you with better service.</p>
                    <p>AyudaDesk will not share your personal information with third parties or individuals you have not authorized.</p>
                    <p>This policy may be amended from time to time. Please check this page to stay up to date.</p>
                    <p>If you have any questions about this privacy statement, the services on this site or any other questions, you can <a routerLink="/contact" >Contact Us</a>.</p>
                    
                    <br/>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="free-trial-area ptb-100 bg-F2F2F2">
    <div class="container">
        <div class="free-trial-content" style="color: #ffffff">
            <h2>HIPAA Compliant Helpdesk</h2>
            <p style="color: #ffffff">AyudaDesk meets the privacy and security needs of healthcare organizations.</p>
            <br />
            <br />
         </div>
    </div>
    
</section>
