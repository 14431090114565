<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>        

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">

                    <!-- <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li> -->

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/features" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Features</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
              
                </ul>
                <div class="others-options"  style="padding-top: 30px; padding-bottom: 30px;">
                    <!-- <a class="default-btn try-now" routerLink="/free-trial" style="color:white; cursor: pointer;margin-right:10px;"><i class="bx bxs-hot"></i>Try Now<span></span></a> -->
                    <a class="default-btn" (click)="redirectDemo('Request Demo')" style="color:white; cursor: pointer;"><i class="bx bxs-hot"></i>Request Demo<span></span></a>
                    <a href="https://support.ayudadesk.com/" target="_blank" class="optional-btn"><i class="bx bx-log-in"></i>Log In<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>